import { useContext, useState } from 'react';
import { GlobalDndContext } from '../../contexts';
import { ArrowsExpandIcon } from '@heroicons/react/outline'
import { elementTypes } from '../../utils/constants';
import { MoveVertical } from 'lucide-react';

export const DragHandle = ({
  children,
  isHandleVisible,
  dragHandleProps,
  forSortableType,
  tooltip,
  id
}) => {

  const [isClickedId, setIsClickedId] = useState(false);
  const { onDragHandleClick, hasActiveDrag, activeDndId, activeFromSidebar } = useContext(GlobalDndContext) || {};
  const isActiveById = activeDndId === id;

  const handleDragHandleClick = () => {
    const styles = ["border-2", "border-dashed", "z-40", "activeBorderDottedDnd"];
    let typeStyle = "border-gray-600";

    switch (forSortableType) {
      case "container":
        typeStyle = "border-blue-600"
        break;
      case "row":
        typeStyle = "border-red-600"
        break;
      case "column":
        typeStyle = "border-green-600"
        break;
      default:
        break;
    }
    
    const element = document.getElementById(`active-${id}`)
    if (element && !id.startsWith('element'))
      element.classList.add(...styles, typeStyle)

    setTimeout(() => {
      const element = document.getElementsByClassName("activeBorderDottedDnd")[0]
      if (element && !id.startsWith('element'))
        element?.classList.remove(...styles, typeStyle)
    }, 4000);
    
    const draggableId = dragHandleProps['data-rbd-drag-handle-draggable-id']
    
    if (draggableId) {
      onDragHandleClick(draggableId)
    }
  }

  const handleProps = dragHandleProps ? { ...dragHandleProps } : {}

  const getPositioningClasses = () => {
    switch (forSortableType) {
      case "column":
        return "absolute top-0 -right-8 z-50"
      case "row":
        return "absolute -left-16 h-full"
      case "container":
        return "absolute -right-9 top-10 z-50"
      default:
        return "absolute z-50 -left-7"
    }
  }

  const getWrapperClassName = () => {

    switch (forSortableType) {
      case "element":
        return "flex flex-row"
      case "column":
        return "relative flex-1"
      default:
        return "relative"
    }
  }

  const getDraggingIconBoxSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-8 h-8"
      case "container":
        return "w-8 h-8"
      default:
        return "w-10 h-10"
    }
  }

  const getDraggingIconSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-5 h-5"
      case "container":
        return "h-4 w-4"
      default:
        return "h-5 w-5"
    }
  }

  const getDraggeableIconPositionByElementType = () => {
    const elementType = children?.props?.children?.props?.element?.type
    switch(elementType) {
      case elementTypes.table:
        return 'mr-8 ml-1'

      default:
        return 'mr-1 ml-1'
    }
  }

  const getDragHandlerFromType = (type) => {
    switch (type) {
      case "element":
        return (
          <div className='ignore-style-change bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M5,11v9c0,0.552,0.448,1,1,1h12 c0.552,0,1-0.448,1-1V4c0-0.552-0.448-1-1-1h-3"></path><path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M12 7V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v3c0 .552.448 1 1 1h5C11.552 8 12 7.552 12 7zM5 15L19 15M15 9L19 9M12 11L12 21"></path><path d="M12,7V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v3c0,0.552,0.448,1,1,1h5C11.552,8,12,7.552,12,7z" opacity=".3"></path>
            </svg>
          </div>
        )
      case "column":
        return (
          <div className='ignore-style-change bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M14 3h4c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1h-4M11 20V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v16c0 .552.448 1 1 1h4C10.552 21 11 20.552 11 20z"></path><path d="M11,20V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v16c0,0.552,0.448,1,1,1h4 C10.552,21,11,20.552,11,20z" opacity=".3"></path><path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M14 9L19 9M5 9L11 9M14 15L19 15M5 15L11 15"></path>
            </svg>
          </div>
        )
      case "row":
        return (
          <div className='ignore-style-change bg-white  border border-gray-300 rounded p-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M19 11v9c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1v-9M19 7V4c0-.552-.448-1-1-1H6C5.448 3 5 3.448 5 4v3c0 .552.448 1 1 1h12C18.552 8 19 7.552 19 7zM5 15L19 15M12 11L12 21M12 6L12 8"></path><path d="M19,7V4c0-0.552-0.448-1-1-1H6C5.448,3,5,3.448,5,4v3c0,0.552,0.448,1,1,1h12C18.552,8,19,7.552,19,7z" opacity=".3"></path>
            </svg>
          </div>
        )
      default:
        return (
          <DraggingIcon />
        )
    }
  }

  const DraggingIcon = () => (<div className={`absolute -right-7 ignore-style-change bg-white shadow sm:rounded-lg flex justify-around items-center ${getDraggingIconBoxSizeClasses()} text-sm `} style={{ cursor: 'grabbing' }}>
    <ArrowsExpandIcon className={`transform rotate-45 group-hover:text-gray-400 ${getDraggingIconSizeClasses()}`} />
  </div>)


  const handleElementClick = () => {
      setIsClickedId(true);
  };

  const handleElementLeave = () => {
    setTimeout(() => {
      setIsClickedId(false);
    }, 2000);
};

  return (
    <div
      className={getWrapperClassName()}
      onClick={handleElementClick}
      onMouseLeave={handleElementLeave}
    >
      {(isHandleVisible && hasActiveDrag() && isActiveById && !activeFromSidebar) && <div className={`z-50 ${getPositioningClasses()}`}> {getDragHandlerFromType(forSortableType)} </div>}
      {
        (isHandleVisible && isClickedId && !hasActiveDrag()) &&
        <div
          className={`${getPositioningClasses()}`}
        >
          {
            forSortableType === "container" && (
              <div
                    className="ignore-style-change bg-white rounded border border-gray-300 w-8 h-8 flex items-center justify-center absolute -right-7"
                    style={{ cursor: 'grab' }}
                    onClick={handleDragHandleClick}
                    title={tooltip}
                    {...handleProps}
                  >
                    <MoveVertical className="w-4 h-4 " />
              </div>
            )
          }
          {
            forSortableType === "row" &&
            <div className='h-full'>
              <div className='m-auto mr-12'>
                <div
                  className=''
                  style={{ cursor: 'grab' }}
                  onClick={handleDragHandleClick}
                  title={tooltip}
                  {...handleProps}
                >
                  {getDragHandlerFromType('row')}
                </div>
              </div>
            </div>
          }
          {
            forSortableType === "column" && (<div
                  className=''
                  style={{ cursor: 'grab' }}
                  onClick={handleDragHandleClick}
                  title={tooltip}
                  {...handleProps}
                >
                  {getDragHandlerFromType('column')}
                </div>
            )
          }
          {
            forSortableType === "element" &&
              <div
                className={`z-50 ${getDraggeableIconPositionByElementType()} absolute -left-2`}
                style={{ cursor: 'grab', alignSelf: "center" }}
                onClick={handleDragHandleClick}
                title={tooltip}
                {...handleProps}
              >
                {getDragHandlerFromType('element')}
              </div>
          }
        </div>
      }
      {children}
    </div>
  )
}